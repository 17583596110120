export default class FormSender {
  static URL_SENDER = '/.netlify/functions/email-sender';
  static URL_UPLOADER = '/.netlify/functions/file-uploader';

  static formatHTML(__data) {
    let html = "";
    const keys = Object.keys(__data);
    keys.forEach((key, index) => {
      html = `${html}<b>${key}:</b> ${__data[key]}<br>`
    });

    return html;
  }

  static formatDatabase(__data) {
    let html = "";
    const keys = Object.keys(__data);
    keys.forEach((key, index) => {
      html = `${html}${key}: ${__data[key]}\n`
    });

    return html;
  }

  static async sendEmail(__data, __replyto) {
    return new Promise((resolve, reject) => {
      const emailSender = new XMLHttpRequest();
      emailSender.open('POST', this.URL_SENDER);
      emailSender.onload = () => {
        if (emailSender.status === 200) {
          resolve(emailSender.status);
        } else {
          reject(emailSender.status);
        }
      };

      const formData = new FormData();
      for (var key in __data) {
        formData.append(key, __data[key]);
      }

      if (__replyto) {
        formData.append("replyTo", __replyto);
      }

      emailSender.send(formData);
    });
  }

  static async sendFile(__file, opts = {}) {
    const formName = opts.formName ? opts.formName : 'Contact';

    return new Promise((resolve, reject) => {
      const fileSender = new XMLHttpRequest();
      fileSender.open('POST', this.URL_UPLOADER);
      fileSender.onload = () => {
        if (fileSender.status === 200) {
          resolve(JSON.parse(fileSender.response));
        } else {
          reject(fileSender.response);
        }
      };

      const formData = new FormData();
      formData.append('form-name', formName);
      formData.append(__file.name, __file.value);

      fileSender.send(formData);
    });
  }
}
