import { SimpleCalendar } from '../../../components/KoraCalendars';
import { GetBy, C } from '../core/Element';
import { ControllerPage } from '../pages/ControllerPage';
import { WinMessage } from '../windows/Message';
import FormSender from './FormSender';

const EMAIL_FILTER =
  /^([a-zA-Z0-9_\.\ñ\Ñ\-])+\@(([a-zA-Z0-9\-\ñ\Ñ])+\.)+([a-zA-Z0-9]{2,4})+$/;

const TELF_FILTER = /^([0-9]+){9}$/;

export default class Forms {
  static validators = [];

  static init() {
    C.forEach('.__form', (e) => { this.validators.push(new FormValidator(e)); })
  }

  static resize() {
    this.validators.map(v => v.resize());
  }

  static dispose() {
    this.validators.map(v => v.dispose());
  }
}

export class FormValidator {
  _form;
  _fields = [];
  _dataSend = {};
  _files = [];
  _calendar = null;
  _calendarFields = [];
  callback;

  constructor(__form, __callback) {
    this.callback = __callback !== undefined ? __callback : this.defaultCb;
    this._submit = (e) => {
      this.prepareSubmit(e);
    }
    this._input = (e) => {
      this.validate(e.target);
    };
    this._focus = (e) => {
      this.focus(e.target);
    };
    this._blur = (e) => {
      this.validate(e.target);
      this.focus(e.target, false);
    };

    this._form = __form;
    this._form.classList.remove('__form');
    this._form.addEventListener('submit', this._submit);

    this.setupValidation();
  }

  setupValidation() {
    const items = [
      ...GetBy.selector('input', this._form),
      ...GetBy.selector('select', this._form),
      ...GetBy.selector('textarea', this._form)
    ];
    C.forEach(items, (item) => {
      this._fields.push(item);
      item.addEventListener('change', this._input);
      item.addEventListener('focus', this._focus);
      item.addEventListener('blur', this._blur);
    });

    const calendar = GetBy.selector('[data-form-calendar]', this._form)[0];
    if (calendar) {
      this._calendar = new SimpleCalendar(calendar);
      this._calendar.init();
      this._calendarFields = [...GetBy.selector('input[type="hidden"]', calendar)];
    }
  }

  removeValidation() {
    C.forEach(this._fields, (item) => {
      item.removeEventListener('change', this._input);
      item.removeEventListener('focus', this._focus);
      item.removeEventListener('blur', this._blur);
    });
    this._fields = [];

    if (this._calendar) {
      this._calendarFields = [];
      this._calendar.dispose();
    }
  }

  focus(__input, focus = true) {
    __input.parentNode.classList[focus ? 'add' : 'remove']('--focus');
  }

  validate(__input) {
    if (!__input) return false;
    if (__input.disabled) return true;

    let valid = true;

    if (__input.dataset.formRequired !== undefined) {
      if (__input.value.split(' ').join('') === '') valid = false;

      if (
        __input.dataset.formEmail !== undefined &&
        !EMAIL_FILTER.test(__input.value)
      ) {
        valid = false;
      }

      if (
        __input.dataset.formTel !== undefined &&
        !TELF_FILTER.test(__input.value)
      ) {
        valid = false;
      }

      if (__input.dataset.formCheckbox !== undefined && !__input.checked) {
        valid = false;
      }

      if (__input.dataset.formRadio !== undefined && !__input.checked) {
        valid = false;
      }

      if (__input.dataset.formPassword !== undefined) {
        const equal = GetBy.id(__input.dataset.formPassword);
        valid = equal.value === __input.value;
      }

      if (__input.dataset.formFile !== undefined) {
        if (__input.files.length) __input.nextElementSibling.innerHTML = __input.files[0].name;
        else valid = false;
      }
    }

    if (valid) {
      __input.parentNode.classList.remove('--error');
      __input.parentNode.classList.add('--success');
    } else {
      __input.parentNode.classList.add('--error');
      __input.parentNode.classList.remove('--success');
    }

    return valid;
  }

  validateCalendar() {
    const dates = this._calendar.getDates();
    let valid = dates.length > 0;

    if (valid) {
      this._calendar.container.classList.remove('--error');
      this._calendar.container.classList.add('--success');
      GetBy.id('date-start').value = dates[0];
      GetBy.id('date-end').value = dates[1];
    } else {
      this._calendar.container.classList.add('--error');
      this._calendar.container.classList.remove('--success');
    }

    return valid;
  }

  check() {
    let valid = true;

    if (this._calendar) {
      valid = this.validateCalendar();
      if (valid) {
        C.forEach(this._calendarFields, (item) => {
          this._dataSend[item.getAttribute('name')] = item.value;
        });
      }
    }

    C.forEach(this._fields, (item) => {
      if (!this.validate(item)) {
        valid = false;
      } else {
        if (item.dataset.formFile !== undefined) {
          this._files.push({
            name: item.getAttribute('name'),
            value: item.files[0]
          });
        } else if (item.dataset.formCheckbox !== undefined) {
          console.log('IS CHECKED', item, item.checked);
          if (item.checked) this._dataSend[item.getAttribute('name')] = item.value;
        } else {
          this._dataSend[item.getAttribute('name')] = item.value;
        }
      }
    });

    return valid;
  }

  prepareSubmit(e) {
    e.preventDefault();

    if (this.check()) this.send();
  }

  async send() {
    const files = [];
    this._files.map((file) => {
      files.push(FormSender.sendFile(file, { formName: this._form.dataset.name }));
    });

    await Promise.all(files)
      .then((res) => {
        res.map((r) => {
          this._dataSend[r.name] = r.url;
        });
      })
      .catch((err) => {
        console.log('Error', err);
      });

    const data = {
      html: FormSender.formatHTML(this._dataSend),
      database: FormSender.formatDatabase(this._dataSend)
    };

    if (!!this._form.getAttribute('data-to')) {
      data.to = this._form.getAttribute('data-to');
    }
    if (!!this._form.getAttribute('data-subject')) {
      data.subject = this._form.getAttribute('data-subject');
    }

    this._form.classList.add('--sending');

    await FormSender.sendEmail(data, this._dataSend.email)
      .then(status => this.callback(status))
      .catch(status => this.callback(status));
  }

  reset() {
    this._dataSend = {};

    C.forEach(this._fields, (item) => {
      item.value = '';
      item.checked = false;
      item.removeAttribute('checked');
      item.parentNode.classList.remove('--error');
      item.parentNode.classList.add('--success');
    });
    this._files = [];

    C.forEach(GetBy.class('__files', this._form), item => {
      item.remove();
    });

    C.forEach(GetBy.class('__filename', this._form), item => {
      item.innerHTML = '';
    });

    this._form.classList.remove('--sending');
  }

  dispose() {
    this._form.removeEventListener('submit', this._submit);
    this.removeValidation();
  }

  resize() {
    if (this._calendar) this._calendar.resize();
  }

  refresh() {
    this.removeValidation();
    this.setupValidation();
  }

  defaultCb = status => {
    const linkThanks = this._form.dataset.mssgOk.startsWith("/") ? this._form.dataset.mssgOk : null;
    this._form.classList.remove('--sending');

    if (status === 200) {
      if (linkThanks) {
        ControllerPage.changePage(linkThanks);
      } else {
        WinMessage.success(this._form.dataset.mssgOk);
        this.reset();
      }
    } else {
      WinMessage.error(this._form.dataset.mssgNok);
    }
  };
}
